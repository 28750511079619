body {
}

.fa-spinner {
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
}
@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}

.sticky-bar {
    position: sticky;
    top: 0;
    background: var(--bulma-scheme-main);
    z-index: 997;
}

.is-toast {
    position: fixed;
    bottom: 20px;
    right: 20px;
    min-width: 200px;
    max-width: 350px;
    z-index: 999;
}

#navbar .navbar-dropdown {
    z-index: 998;
}

.htmx-request-only {
    display: none;
}

.htmx-request .htmx-request-only {
    display: inline;
}

.htmx-request .htmx-request-hide {
    display: none;
}

.dpp-keyword {
    font-family: monospace;
    font-weight: 400;
    user-select: all;
    overflow-x: scroll;
    white-space: nowrap;
}

.panel {
    --bulma-panel-shadow: 0 0 0 1px hsla(var(--bulma-shadow-h), var(--bulma-shadow-s), var(--bulma-shadow-l), 0.1);
}

.media-content {
    overflow-x: visible;
}
